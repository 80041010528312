<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2 c-title2--mb16">
        以下のオンラインストアアカウントと連携しています
      </h1>
      <p class="p-contact__thanks">
        アプリとオンラインストアの連携が完了しました。アプリを使ってお得にお買い物を楽しみましょう。
      </p>

      <div class="p-linkOnlineStore__userInfo">
        <div class="p-linkOnlineStore__userInfo__item">
          <div class="c-title6 c-title6--mb4">氏名</div>
          <div>姓：{{ user.lastName }}</div>
          <div>名：{{ user.firstName }}</div>
        </div>
        <div class="p-linkOnlineStore__userInfo__item">
          <div class="c-title6 c-title6--mb4">オンラインストアID</div>
          <div>{{ user.id }}</div>
        </div>
      </div>

      <div class="p-linkOnlineStore__unlink">
        <a :href="unlinkUrl">連携を解除する</a>
      </div>

      <Button
        :href="$customUrlScheme.page('root/home/1')"
        style-type="tertiary"
        fluid
        flat
        class="p-linkOnlineStore__action">
        トップへ戻る
      </Button>

      <p class="p-linkOnlineStore__notes">
        ※獲得したポイントのご利用には連携が必要です
      </p>
    </div>
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    unlinkUrl() {
      return this.$systemDialog(
        SystemDialogMessage.ONLINE_STORE_CONNECT_CANCEL
      );
    }
  }
};
</script>
